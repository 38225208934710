.tasksBoard {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    box-sizing: border-box;
    flex: 1 1 auto;
}

.tasksColumnContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #202023;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    position: relative;
}

.tasksColumn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /*height: 100%;*/
    box-sizing: border-box;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 52px;
}

.tasksColumnTitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 1 auto;
}

.tasksColumnTitle {
    font-family: SFPro, serif;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 0 10px;
    line-height: 16px;
}

.tasksColumnList {
    display: flex;
    /*min-height: 170px;*/
    flex-direction: column;
    overflow-y: auto;
    /*max-height: 67vh;*/
    border-radius: 10px;
    /*height: 100%;*/
    flex: 1 1 auto;
}


.taskCard {
    margin: 5px 0;
    border-radius: 10px;
    padding: 5px 10px;
    background: #313134;
    display: flex;
    flex-direction: column;
    gap: 3px;


    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.taskCard:hover {
    background: #39393c;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.taskColumn__AddTaskButtonContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
}

.taskColumnAddTaskButton {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
