.editor {
    min-width: 600px;
    width: 100%;
    position: relative;
    z-index: 0;
}

.noteTitleContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.noteTitle {
    max-width: 1000px !important;
    min-width: 600px !important;
    width: 100%;
}
