@keyframes show {
    from {
        opacity: 0;
        transform: translateY(-15px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    background: #313134;
    border-radius: 10px;

    animation: show 0.5s ease-in-out;
}

.container:hover {
    background: #39393c;
}

.description {
    margin: 0 10px !important;
    padding: 0;

    font-family: SFPro, serif;
    font-size: 16px;

    color: #BCBBBB;

}

.datePicker {
    font-family: SFPro, serif;
    font-size: 16px;
    width: 110px;
    background: var(--color-gray-200);
}

.noDescription {
    color: var(--color-white-100);
}

.deleteIcon {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.deleteIcon:hover path {
    stroke: var(--color-white-100)
}
