@keyframes showDown {
    from {
        opacity: 0;
        transform: translateY(15px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes showUp {
    from {
        opacity: 0;
        transform: translateY(-15px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes showLeft {
    from {
        opacity: 0;
        transform: translateX(-15px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes showRight {
    from {
        opacity: 0;
        transform: translateX(15px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes showFade {
    from { opacity: 0 }
    to { opacity: 1 }
}


.mainContainer{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px 70px;
    margin-left: 60px;
    justify-content: center;
}

.inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90vh;
}

.container {
    max-height: 600px;
    height: 100%;
}
