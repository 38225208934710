:root{
    /* Colors */
    --color-gray-100: #4F4F53;
    --color-gray-200: #313134;
    --color-gray-300: #242428;
    --color-gray-400: #202023;
    --color-gray-500: #030303;

    --color-white-100: #737373;
    --color-white-200: #BBBBBB;
    --color-white-300: #C2C2C2;
    --color-white-400: #BCBBBB;
    --color-white-500: #FFFFFF;

    /* Border Radius */
    --border-radius-s: 10px;
    --border-radius-m: 15px;
    --border-radius-l: 20px;
    --border-radius-xl: 30px;

    /* Fonts */
    --main-font: SFPro, serif;
}
