@keyframes show {
    from {
        opacity: 0;
        transform: translateY(15px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.authorizationForm__formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    animation: show 1s ease-in-out;
    width: 100%;
    padding: 20px 0;
}

.authorizationForm__header {
    font-family: SFPro, serif;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 40px;
    text-align: center;
    padding: 20px 0;
    line-height: 40px;
}
