.menu {
    width: 60px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #202023;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
