@keyframes showDown {
    from {
        opacity: 0;
        transform: translateY(15px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes showUp {
    from {
        opacity: 0;
        transform: translateY(-15px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes showLeft {
    from {
        opacity: 0;
        transform: translateX(-15px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes showRight {
    from {
        opacity: 0;
        transform: translateX(15px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes showFade {
    from { opacity: 0 }
    to { opacity: 1 }
}


.mainContainer{
    display: flex;
    flex-direction: column;
    gap: 30px;
    /*padding: 20px;*/
    /*margin: 20px;*/
    padding: 20px 70px;
    margin-left: 60px;
    justify-content: center;
}

.welcomePage {
    /*width: 100vw;*/
    /*height: 100vh;*/
}

.workContainer {
    min-width: 600px !important;
    /*max-width: 1000px !important;*/
    width: 100%;
}

.noteTitleContainer {
    display: flex;
    justify-content: center;
}

.noteTitle {
    max-width: 1000px !important;
    min-width: 600px !important;
    width: 100%;
}

.pageTitle {
    font-family: SFPro, serif;
    font-weight: 600;
    font-size: 32px;
    width: 100%;
}

/*.schemes {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    gap: 20px;*/
/*    flex-wrap: wrap;*/
/*}*/
.schemes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
    grid-auto-columns: 300px;
    margin: auto;

    max-width: 1000px;
    width: 100%;
    box-sizing: border-box;
}

/*.notes {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    flex-wrap: wrap;*/
/*    gap: 20px;*/
/*    margin: 0 auto;*/
/*    justify-content: start;*/
/*}*/

.notes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
    grid-auto-columns: 300px;
    margin: auto;

    max-width: 1000px;
    width: 100%;
}

.createNoteOwner {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.createNoteOwnerName {
    font-family: SFPro, serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #97989F;
}

.notesPageNoNotes {
    grid-column: 2;
    text-align: center;
    color: #737373;
}

.notePageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tasksPageHeaderPanel {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 75vw;
    width: 100%;
}
